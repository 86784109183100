import ReactGA from "react-ga4";

export function eventClick(name){
    ReactGA.event({
      category: "your category",
      action: name,
      label: "your label", // optional
      value: 99, // optional, must be a number
      nonInteraction: true, // optional, true/false
      transport: "xhr", // optional, beacon/xhr/image
    });
    }