import React from 'react';
import ReactGA from "react-ga4";
import Privacy from '../PrivacyStatment';
import {eventClick} from "../../commonFunctions/eventClick";

const Model=()=>{
  
    return(
        <div>

<a  data-toggle="modal" data-target="#myModal2" onClick={()=>eventClick("privacyClick")} >Privacy Policy</a>

<div class="modal fade" id="myModal2" role="dialog">
    <div class="modal-dialog modal-lg">

      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal">&times;</button>
        </div>
        <div class="modal-body">
        <Privacy/>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
        </div>
      </div>
      </div>
      </div>


        </div>
    )
}

export default Model;