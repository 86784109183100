import React from 'react';
// import retail from  './e-come.png';

const Services = () => {
    return (
        <div id="services" className="container-fluid text-center">
            <h2>Products</h2>
            <h4>What we offer</h4>
            <br />
            <div className="row slideanim">
                <div className="col-sm-4">
                <span class="bi bi-phone logo-small"></span>
                    <h4>MOBILE APPS</h4>
                    {/* <p>Lorem ipsum dolor sit amet..</p> */}
                </div>
                <div className="col-sm-4">
                <span class="bi bi-tv logo-small"></span>
                    {/* <span className="glyphicon glyphicon-tv logo-small"></span> */}
                    <h4>WEB APPS</h4>
                    {/* <p>Lorem ipsum dolor sit amet..</p> */}
                </div>
                <div className="col-sm-4">
                    {/* <span className="glyphicon glyphicon-wrench logo-small"></span> */}
                    <span class="bi bi-gear logo-small"></span>
                    <h4 style={{color: '#303030'}}>SERVICES</h4>
                    {/* <p>Lorem ipsum dolor sit amet..</p> */}
                </div>
                {/* <div className="col-sm-4">
                    <span className="glyphicon glyphicon-lock logo-small"></span>
                    <h4>JOB DONE</h4>
                    <p>Lorem ipsum dolor sit amet..</p>
                </div> */}
            </div>
            <br /><br />
            <div className="row slideanim">
                {/* <div className="col-sm-4">
                    <span className="glyphicon glyphicon-leaf logo-small"></span>
                    <h4>GREEN</h4>
                    <p>Lorem ipsum dolor sit amet..</p>
                </div> */}
                {/* <div className="col-sm-4">
                    <span className="glyphicon glyphicon-certificate logo-small"></span>
                    <h4>CERTIFIED</h4>
                    <p>Lorem ipsum dolor sit amet..</p>
                </div> */}
             
            </div>
        </div>

    )
}

export default Services;