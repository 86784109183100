import React from 'react';
import { Link } from 'react-router-dom';
import TermsModel from "./commonComponents/termsModel";
import Privacy from "./commonComponents/privacyModel";

const Footer = () => {
    return (
        <footer class="container-fluid text-center">
            
            <a href="#myPage" title="To Top">
                <span class="glyphicon glyphicon-chevron-up"></span>
            </a>

            <div className="row">
                    <div className="col-sm-3">
                       <h4> AKARAVA</h4>
                       {/* <small>© Akarava Private Limited</small> */}
                       <small>© 2023 Akarava Private Limited.</small>

                        </div>

                        <div className="col-sm-3">
                            <Privacy/>
                            {/* <Link to='privacy-statment'>Privacy Policy</Link> */}
                        </div>

                        <div className="col-sm-3">
                        <TermsModel/>
                            {/* <Link to='terms-of-use'>Terms of Use</Link> */}
                        </div>

                        

                        <div className="col-sm-3">
                        </div>
                        </div>
            {/* <p>Theme Made By <a href="#" title="Visit w3schools">Chandana</a></p> */}
        </footer>
    )
}

export default Footer;