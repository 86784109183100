import React, { useState } from "react";

const DeleteAccount=()=>{

    const [mobile,setMobile]=useState("");
    const [otp,setOtp]=useState("");
    const [otpSent,setOtpSent]=useState(false);
    const [error,setError]=useState("");


const sendOtpClick=()=>{
    if(mobile&&mobile.length==10){
    setOtpSent(true);
    setError("");
    }else{
        setError("Please Enter correct mobile number!");
    }
}

const submitClick=()=>{
    if(!otp){
        setError("Please enter otp!")
    }if(otp.length!==4){
        setError("Please enter 4 digit OTP!")
    }else{
        // alert("Your account deleted!")
        setError("Error!")
    }

}

    return(
        <div className="container-fluid" >
{!otpSent?

            <div style={{marginTop:"1em", textAlign:"center"}}>
            <input type="number" placeholder="Mobile Number" onChange={e=>setMobile(e.target.value)}
            style={{height: "3em", width: "15em", borderRadius: "10px",textAlign: "center"}}
            ></input>
            <br/>
            <button className="btn-primary"
            style={{borderRadius: "5px",marginTop:"1em"}}
            onClick={sendOtpClick}
            >Send OTP</button>
            </div>
:""}
{otpSent?
 <div style={{marginTop:"1em", textAlign:"center"}}>
<input type="text" placeholder="Enter OTP" onChange={e=>setOtp(e.target.value)}
            style={{height: "3em", width: "15em", borderRadius: "10px",textAlign: "center"}}
            ></input>
            <br/>
            <button className="btn-primary"
            style={{borderRadius: "5px",marginTop:"1em"}}
            onClick={submitClick}
            >SUBMIT</button>
</div>
:""}

 <div style={{marginTop:"1em", textAlign:"center"}}>
<small style={{color:"red"}}>{error}</small>
</div>
        </div>
    )
}

export default DeleteAccount;