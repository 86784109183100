import React from 'react';
import ReactGA from "react-ga4";
import download from'../images/newone.png';
// import download from'../images/akaravaLogo.png';


const Navbar = () => {

    function eventClick(name){
    ReactGA.event({
      category: "your category",
      action: name,
      label: "your label", // optional
      value: 99, // optional, must be a number
      nonInteraction: true, // optional, true/false
      transport: "xhr", // optional, beacon/xhr/image
    });
    }

    return (
        <nav className="navbar navbar-default navbar-fixed-top">
            <div className="container">
                <div className="navbar-header">
                    <button type="button" className="navbar-toggle" data-toggle="collapse" data-target="#myNavbar">
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                    </button>
                    <p style={{display:"flex"}}>
                    <img src={download}  style={{width:'6em',height:'4em'}} ></img>
                    <a  style={{fontFamily:'inherit',fontWeight:'800'}} className="navbar-brand" href="#myPage">AKARAVA</a>
                    </p>

                </div>
                <div className="collapse navbar-collapse" id="myNavbar">
                    <ul className="nav navbar-nav navbar-right">
                        <li><a href="#about" onClick={eventClick("aboutClick")}>ABOUT</a></li>
                        <li><a href="#services" onClick={eventClick("productsClick")}>PRODUCTS</a></li>
                        {/* <li><a href="#portfolio">GALLERY</a></li> */}
                        {/* <li><a href="#pricing">PRICING</a></li> */}
                        <li><a href="#contact" onClick={eventClick("contactClick")}>CONTACT</a></li>
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;