import React, { Fragment,useEffect } from 'react';
import home from  '../images/1385386.jpg';
import mission from  '../images/istockphoto-1364358321-170667a.jpg';


// import download from'./download.png';
// home-footer-careers-updated_Small

const About = () => {


    return (
        <div>
        <Fragment  >
            <div id="about"  style={{backgroundColor:'black',fontFamily:'Montserrat, '}}className="container-fluid" >
                <div className="row">
                    <div className="col-sm-8">
                        <h2  style={{color:'#b4b4b8'}}>About Company Page</h2><br />
                        <p style={{color:'#rgb(180 180 184)',textAlign:'justify'}}> <h4 style={{color:'#b4b4b8'}}>Our company is a leading software development firm specializing in creating innovative e-commerce and tracking applications. We understand the importance of technology in today's fast-paced world and strive to provide businesses with top-notch solutions that simplify their operations and enhance their overall efficiency.<br/></h4>

We offer a range of services that cater to the diverse needs of our clients. Whether you are looking to establish a robust online presence for your business or streamline your supply chain operations, we have the expertise and experience to deliver the results you need. Our team of skilled developers works closely with clients to understand their unique requirements and develop tailored solutions that meet their specific needs.

We are dedicated to delivering high-quality products that are user-friendly, reliable and scalable. Our cutting-edge technologies and advanced development processes ensure that we stay ahead of the curve and continue to deliver the most advanced and effective solutions for our clients.

If you're looking for a partner who can help you harness the power of technology and drive your business forward, look no further than our software company. Contact us today to learn more about how we can help you achieve your goals and grow your business.</p>
                        {/* <h4  style={{color:'#b4b4b8'}}> */}
                            {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</h4><br /> */}
                        {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p> */}
                        <br />
                        <a href='#contact'>
                        <button  style={{background:
                        'linear-gradient(102.57deg, rgb(19, 109, 10), rgb(20, 300, 290) 100%, rgb(244, 31, 214) 0px)'
                            // 'linear-gradient(102.57deg,#dc0916,#f41fd6 100%,#f41fd6 0)'
                        }}
                             className="btn btn-default btn-lg">Get in Touch</button>
                        </a>
                        
                    </div>
                    <div className="col-sm-4">
                        <span 
                     
                        // className="glyphicon glyphicon-signal logo"
                        >
                             <img style={{width:'100%',height:'34em'}} src={mission}></img> 
                            
                        </span>
                       
                    </div>
                </div>
            </div>

            <div  style={{backgroundColor:'black'}} className="container-fluid bg-grey">
                <div className="row">
                    <div className="col-sm-4">
                        <span 
                        
                        // className="glyphicon glyphicon-globe logo slideanim"
                        >
 <img style={{width:'100%',height:'28em'}} src={home}></img> 
                        </span>
                    </div>
                    <div className="col-sm-8">
                        <h2 style={{color:'#b4b4b8'}}>Our Values</h2><br />
                        <h4 style={{color:'#b4b4b8'}}><strong style={{color:'#b4b4b8'}}>MISSION:</strong> Our mission is to empower businesses with innovative technology solutions that simplify their operations, enhance their productivity and drive their growth. We are dedicated to delivering the highest quality products and services, and building long-lasting partnerships with our clients..</h4><br />
                        <p><strong style={{color:'#b4b4b8'}}>VISION:</strong> Our vision is to be the leading provider of e-commerce and tracking solutions, and to continuously push the boundaries of what is possible with technology. We strive to create a positive impact on the world through our products and services, and to be recognized as a trusted and reliable partner for businesses of all sizes..</p>
                    </div>
                </div>
            </div>
        </Fragment>
        </div>
    )
};

export default About;
