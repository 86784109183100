import React from 'react';
import axios from "axios";
import ReactGA from "react-ga4";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoadingGif from "../images/giphy.gif";

const Contact = () => {

    const [mobile,setMobile]=React.useState("");
    const [name,setName]=React.useState("");
    const [comment,setComment]=React.useState("");
    const [error,setError]=React.useState("");
    const [loading,setLoading]=React.useState(false);


    console.log(mobile,name,comment,"dataaaa")
    
    function eventClick(name){
        ReactGA.event({
          category: "your category",
          action: name,
          label: "your label", // optional
          value: 99, // optional, must be a number
          nonInteraction: true, // optional, true/false
          transport: "xhr", // optional, beacon/xhr/image
        });
        }

    const sendClick=async ()=>{
        setLoading(true);
        eventClick("sendClick");
        if(!mobile){
            toast.error("please enter mobile number");
        }else if(mobile.length!==10){
            toast.error("please enter 10 digit mobile number");
        }else if(!name){
            toast.error("please fill name");
        }else{
            setError("")
        let url="https://duobmr97gk.execute-api.us-east-1.amazonaws.com/dev/contactUs/";
        const {data} = await axios.post(url, {mobile,name,comment}, {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          console.log(data.status,"dataaaaa");
          if(data.status){
            setMobile("");
            setComment("");
            setName("");
            toast.success("Sucess!");
          }else{
            toast.error("Error- "+data.error);
          }
        }
        setLoading(false);

    }

    return (
        <div id="contact" class="container-fluid bg-grey">
            <h2 class="text-center">CONTACT</h2>
            <div class="row">
                <div class="col-sm-5">
                    <p>Contact us and we'll get back to you.</p>
                    <p><span class="glyphicon glyphicon-map-marker"></span> HYDERABAD, INDIA.</p>
                    <p><span class="glyphicon glyphicon-phone"></span>+91 7075532137</p>
                    <p><span class="glyphicon glyphicon-envelope"></span> akaravatech@gmail.com</p>
                </div>
                <div class="col-sm-7 slideanim">
                    <div class="row">
                        <div class="col-sm-6 form-group">
                            <input class="form-control" style={{border:!mobile || mobile.length!==10?"1px solid red":""}} id="name" name="name" placeholder="Mobile" type="number" value={mobile} onChange={(e)=>setMobile(e.target.value)} required />
                        </div>
                        <div class="col-sm-6 form-group">
                            <input class="form-control" id="email" name="name" style={{border:!name?"1px solid red":""}} placeholder="Name" type="text" value={name} onChange={(e)=>setName(e.target.value)} required />
                        </div>
                    </div>
                    <textarea class="form-control"
                     id="comments" name="comments"
                     value={comment}
                    //  style={{border:!comment?"1px solid red":""}} 
                      placeholder="Comment" rows="5" onChange={(e)=>setComment(e.target.value)} ></textarea><br />
                    <div class="row">
                        <div class="col-sm-12 form-group">
                            <button class="btn btn-default pull-right" type="submit" onClick={sendClick} >{loading ? "Loading..." : "Send"} </button>
                        </div>
                    </div>
                    <ToastContainer />
                </div>
            </div>
        </div>
    )
}

export default Contact;