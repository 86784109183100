import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from './Navbar';
import Hero from './Hero';
import About from './About';
import Services from './Services';
import Portfolio from './Portfolio';
import Testimonial from './Testimonial';
import Pricing from './Pricing';
import Contact from './Contact';
import Footer from './Footer';



function App() {
  return (
    <div className="App">

      <Hero />
      <About />
      <Services />
      {/* <Portfolio /> */}
      <Testimonial />
      {/* <Pricing /> */}
      <Contact />
      <Footer />

    </div>
  );
}

export default App;
