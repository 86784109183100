import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import Layout from "./components/Layout";
import PrivacyStatment from "./components/PrivacyStatment";
import Navbar from './components/Navbar'
import TermsOfUse from './components/TandC';
import Footer from './components/Footer';
import DeleteAccount from "./components/raw-app/deleteAccount";

ReactGA.initialize('G-BYGETQ0NNK');

function App() {

  useEffect(()=>{
    ReactGA.send({
      hitType: "homePage",
      page: "/home",
    });

    // ReactGA.event({
    //   category: "your category",
    //   action: "your action",
    //   label: "your label", // optional
    //   value: 99, // optional, must be a number
    //   nonInteraction: true, // optional, true/false
    //   transport: "xhr", // optional, beacon/xhr/image
    // });

  },[])

  return (
    <div className="App">
      <Navbar />

      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />} />
          <Route path="/privacy-statment" element={<PrivacyStatment />} />
          <Route path="/privacy-policy" element={<PrivacyStatment />} />
          <Route path="/privacy" element={<PrivacyStatment />} />
          <Route path="/privacy.html" element={<PrivacyStatment />} />
          <Route path="/terms-of-use" element={<TermsOfUse />} />
          <Route path="/delete-user" element={<DeleteAccount />} />

          

        </Routes>
      </BrowserRouter>

      {/* <Footer /> */}

    </div>
  );
}

export default App;
