import React from 'react';
import Footer from './Footer';

import "./styles.css";

const TandC = () => {
    return (
<div className='container-fluid'>
   <h2> Terms Of Use</h2>

   <p>Welcome to Akarava. These Terms and Conditions of Use ("Terms") govern your access to and use of the Akarava mobile application ("App"), operated by Akarava ("Akarava", "we", "us", or "our"). By accessing or using the App, you agree to be bound by these Terms, which constitute a legally binding agreement between you and Akarava. If you do not agree to these Terms, you may not access or use the App.</p>

   <b><li>App Use</li></b>
   <p>Akarava provides an App that allows users to purchase grocery items. The App is intended for personal and non-commercial use only. You may not use the App for any other purpose, including but not limited to commercial purposes, without our express prior written consent.</p>

<b><li>Account Creation</li></b>
<p>In order to use the App, you must create an account. You are responsible for maintaining the confidentiality of your account information, including your password, and for all activity that occurs under your account. You agree to notify Akarava immediately of any unauthorised use of your account or password, or any other breach of security.</p>

<b><li>Orders and Payments</li></b>
<p>You may place orders for grocery items through the App. All orders are subject to acceptance by Akarava. Akarava reserves the right to refuse or cancel any order for any reason at any time, including but not limited to product availability, pricing errors, or suspected fraud or abuse.
You agree to pay for all products you order through the App. Akarava accepts payment via credit card, debit card, or other payment methods that may be offered through the App.
</p>
<p> The Company shall not be responsible for any unauthorised transactions conducted on the Platform using Your Payment Details. The Company shall not be obligated to refund any money to You in such instances.</p>


<b><li>Delivery</li></b>
<p>Akarava offers delivery of grocery items through the App. Delivery is subject to availability and may be delayed or cancelled due to factors beyond our control. Akarava will make reasonable efforts to ensure timely and accurate delivery of products, but does not guarantee delivery times or dates.</p>
<p>You agree to provide accurate delivery information, including your delivery address and contact information. Akarava is not responsible for any delays or delivery failures due to inaccurate or incomplete information provided by you.</p>

<b><li>Returns and Refunds</li></b>
<p>Akarava does not offer returns or refunds for grocery items purchased through the App, except in the case of damaged or defective products. If you receive a damaged or defective product, please contact Akarava customer service within 24 hours of delivery to request a refund or replacement.</p>


<b><li>Intellectual Property</li></b>
<p>The App and its content, including but not limited to text, graphics, logos, images, and software, are the property of Akarava or its licensors and are protected by copyright, trademark, and other intellectual property laws. You may not use, reproduce, distribute, or display any content from the App without our express prior written consent.</p>


<b><li>Disclaimer of Warranties</li></b>
<p>THE APP IS PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS, WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT. Akarava MAKES NO WARRANTY THAT THE APP WILL MEET YOUR REQUIREMENTS OR BE AVAILABLE ON AN UNINTERRUPTED, SECURE, OR ERROR-FREE BASIS.</p>


<b><li>Limitation of Liability</li></b>
<p>IN NO EVENT SHALL Akarava BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES ARISING OUT OF OR RELATED TO THESE TERMS OR YOUR USE OF THE APP, INCLUDING BUT NOT LIMITED TO LOSS OF PROFITS, LOSS OF DATA, OR BUSINESS INTERRUPTION, WHETHER IN CONTRACT, TORT, STRICT LIABILITY, OR OTHERWISE, EVEN IF Akarava HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</p>


<b><li>Cancellation Policy</li></b>
<p>You may cancel your order at any time before it is shipped. To cancel your order, you can  cancel your order in app orders section. We will cancel your order and issue a full refund within 5-7 business days.</p>


<b><li>Important User declaration:</li></b>
<p>You agree and undertake that You will not provide the address of any public place, including but not limited to, educational institution, hospital, religious places as Your delivery address for the order relating to alcoholic beverage and tobacco products.Further, You agree and undertake, when You buy alcohol, that You are not;</p>
<p>a.in drunken condition;</p>
<p>b.taking part in any unlawful activity;</p>
<p>c.(uniformed official) on duty or in uniform; and</p>
<p>d.below the legal drinking age.</p>
<p>The Company and/or the third party delivery service providers reserves the right to refuse delivery of such order to You in case of any non –compliance by You of the above conditions in the bona fide opinion of the Company or the third party delivery service providers.</p>


<b><li>Beware of fraud</li></b>
<p>  Please do not share Your debit/credit card number, CVV number,OTP, UPI/ATM pin and other sensitive information with anyone claiming to be aCompany representative. Company or its authorised representatives will NEVER ask You to share the aforesaid details. Beware of fraudsters and please report incidents immediately to Your bank, the nearest police station and at https://cybercrime.gov.in/.</p>
<p> For assistance on a Akarava order or refund related issues, click on the ‘Get Help’ section on the App.</p>
<p>Please exercise caution to verify the portals/website links claiming to be Akarava or a lookalike or a payment link shared over social media or a social messaging apps claiming to be Akarava discounts or offers and proactive calls from unauthorised numbers requesting for personal/financial information.</p>

<b><li>INTELLECTUAL PROPERTY RIGHTS</li></b>
<p>All content included in the Akarava mobile application ("App"), including but not limited to text, graphics, logos, images, and software, is the property of Akarava or its content suppliers and is protected by United States and international copyright and trademark laws.</p>
<p>You may not modify, copy, reproduce, republish, upload, post, transmit, or distribute any material from the App in any way without Akarava's prior written permission. You may download material from the App for your personal, non-commercial use only, provided you keep intact all copyright and other proprietary notices.</p>
<p>The Akarava name and logo are trademarks of Akarava. All other trademarks, service marks, and trade names used in the App are the property of their respective owners.</p>
<p>If you believe that your intellectual property rights have been violated in connection with the App, please contact us at akaravatech@gmail.com. We will investigate the matter and take appropriate action.</p>
<p>By using the App, you agree to abide by all copyright and trademark laws and any other applicable laws regarding intellectual property.</p>

<b><li>DISCLAIMER OF WARRANTIES & LIABILITY</li></b>
<p>Akarava provides the App and its content on an "as is" and "as available" basis. We do not make any representations or warranties of any kind, express or implied, as to the operation of the App, the information, content, materials, or products included on the App, or the reliability, accuracy, completeness, or timeliness of any information, content, materials, or products provided through the App.</p>
<p>To the fullest extent permissible by applicable law, Akarava disclaims all warranties, express or implied, including but not limited to implied warranties of merchantability, fitness for a particular purpose, and non-infringement. We do not warrant that the App will be uninterrupted or error-free, that defects will be corrected, or that the App or the servers that make it available are free of viruses or other harmful components.</p>
<p>Akarava shall not be liable for any damages of any kind arising from the use of the App, including but not limited to direct, indirect, incidental, punitive, and consequential damages. We shall not be liable for any damages resulting from the use or inability to use the App, including but not limited to damages for loss of profits, use, data, or other intangible property, even if we have been advised of the possibility of such damages.</p>
<p>Some jurisdictions do not allow the exclusion of certain warranties or the limitation or exclusion of liability for incidental or consequential damages. Accordingly, some of the above limitations and disclaimers may not apply to you. To the extent that we may not, as a matter of applicable law, disclaim any implied warranty or limit its liabilities, the scope and duration of such warranty and the extent of our liability shall be the minimum permitted under such applicable law.</p>
<p>By using the App, you acknowledge that you have read this disclaimer of warranties and liability and agree to all terms and conditions contained herein.</p>


<b><li>Violation of these Terms</li></b>
<p>You agree that any violation by You of these Terms will likely cause irreparable harm to the Company, for which monetary damages would be inadequate, and You consent to the Company obtaining any injunctive or equitable relief that they deem necessary or appropriate in such circumstances.These remedies are in addition to any other remedies that the Company may have at law or in equity.</p>


<b><li>GRIEVANCE REDRESSAL MECHANISM</li></b>
<p>At Akarava, we strive to provide the best possible experience to our users. However, if you have a complaint or grievance regarding any aspect of the App or our services, we have established a Grievance Redressal Mechanism to address your concerns.
If you have a complaint or grievance, please contact our customer support team at akaravatech@gmail.com. We will make every effort to resolve your complaint in a timely and satisfactory manner.</p>
<p>If you are not satisfied with the resolution of your complaint, you may escalate your grievance by contacting our Grievance Officer at grievance.officer@Akarava.com. The Grievance Officer will review your complaint and take appropriate action to address your concerns.
We request that you provide as much detail as possible when submitting your complaint or grievance, including your name, contact information, and a clear description of the issue you are experiencing. This will help us to resolve your complaint more efficiently and effectively.
We value your feedback and are committed to addressing any complaints or grievances in a fair and timely manner.
</p>

<b><li>COMMUNICATIONS</li></b>
<p>By using the Akarava mobile application, you consent to receive electronic communications from us, including but not limited to emails, push notifications, and in-app messages. You agree that any electronic communication we send to you satisfies any legal requirement that such communication be in writing.</p>
<p>We may use electronic communication to provide you with information about our services, promotions, and other updates. You may opt-out of receiving promotional communications by following the instructions in the communication or by contacting our customer support team at akaravatech@gmail.com.</p>
<p>We may also communicate with you regarding your use of the App, including but not limited to any updates, changes, or modifications to the App or these Terms and Conditions. You may not opt-out of these types of communications, as they are necessary for us to provide you with the best possible experience when using the App.</p>
<p>You are responsible for maintaining accurate and up-to-date contact information, including your email address and phone number, in your account settings. We shall not be liable for any damages resulting from your failure to receive electronic communications from us due to inaccurate or outdated contact information.</p>
<p>If you have any questions or concerns regarding our communication policies, please contact our customer support team at akaravatech@gmail.com.</p>

<b><li>GENERAL PROVISIONS</li></b>
<p>1.Assignment: You may not assign or transfer your rights or obligations under these Terms and Conditions without our prior written consent. We may assign or transfer these Terms and Conditions, in whole or in part, at any time without notice to you.</p>
<p>2.Severability: If any provision of these Terms and Conditions is found to be invalid, unlawful, or unenforceable, such provision shall be deemed severable from these Terms and Conditions and shall not affect the validity and enforceability of any remaining provisions.</p>
<p>3.Waiver: Our failure to enforce any right or provision of these Terms and Conditions shall not be deemed a waiver of such right or provision.</p>
<p>4.Entire Agreement: These Terms and Conditions, together with our Privacy Policy and any additional terms to which you agree when using particular elements of the App, constitute the entire agreement between you and Akarava with respect to your use of the App.</p>
<p>5.Force Majeure: We shall not be liable for any failure or delay in the performance of our obligations under these Terms and Conditions, if such failure or delay is due to causes beyond our reasonable control, including but not limited to acts of God, war, terrorism, strikes, or other labor disputes, riots, civil disturbances, nuclear accidents, or natural disasters.</p>
<p>6.Modification: We reserve the right to modify these Terms and Conditions at any time without notice to you. Your continued use of the App following any such modification constitutes your agreement to be bound by the modified Terms and Conditions.</p>
<p>7.Contact Information: If you have any questions or concerns regarding these Terms and Conditions, please contact our customer support team at akaravatech@gmail.com.</p>


<b><li>ADVERTISEMENTS</li></b>
<p>Akarava may display advertisements on the App, including but not limited to banner ads, interstitial ads, and native ads. These advertisements may be targeted to users based on their location, search history, or other criteria.
The inclusion of any advertisements on the App does not imply endorsement by Akarava of any advertised products or services. Akarava is not responsible for the content of any advertisements, and we do not make any warranties or representations regarding the accuracy, reliability, or quality of any products or services advertised on the App.
Akarava reserves the right to reject or remove any advertisement from the App for any reason, including but not limited to advertisements that are false, misleading, or in violation of applicable laws or regulations.
If you click on an advertisement on the App, you will be redirected to a third-party website or app. Akarava is not responsible for the content, products, or services offered on any third-party website or app, and we do not make any warranties or representations regarding the accuracy, reliability, or quality of any products or services offered on third-party websites or apps.
By using the App, you acknowledge and agree that Akarava may display advertisements on the App and that you will not hold Akarava liable for any damages or losses arising from your interactions with any third-party advertisements or websites.
</p>

<b>© 2023 Akarava, All rights reserved.</b>


   </div>
    )
}

export default TandC;